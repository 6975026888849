import styled from 'styled-components';

export const Body = styled.div`
  text-align: left;
  font-size: 16px;
  line-height: 1.8em;
`;

export const Note = styled.div`
  font-style: italic;
  font-size: 14px;
  color: #999;
`;

export const Table = styled.table`
  th {
    font-weight: 600;
  }

  margin: 50px 0 0;
`;
