import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Image } from 'react-feather';
import Pluralize from 'react-pluralize';
import { reject, sumBy } from 'lodash';
import { BrowserView } from 'react-device-detect';

import { Anchor } from '../../styles/base';
import { ORDER_CONFIRMATION } from '../../constants/Routes';
import { S3_ENDPOINT } from '../../constants/External';

import { Container, Photo, PhotoQuantityHandler, QuantityHandler } from './style';

class GalleryOrderMode extends Component {
  constructor(props) {
    super(props);

    const { albumName, albumId, albumSlug } = this.props;

    this.state = {
      loadedPhotos: 0,
      currentOrder: {
        albumName,
        albumId,
        albumSlug,
        photos: []
      }
    }
  }

  componentDidMount() {
    const localCurrentOrder = typeof localStorage !== 'undefined' && localStorage.getItem('currentOrder');
    const { currentOrder } = this.state;

    this.setState({
      ...this.state,
      currentOrder: JSON.parse(localCurrentOrder) || currentOrder
    });
  }

  addPhotoToState = (photo) => {
    console.log(photo);

    const { currentOrder } = this.state;

    if (this.photoIsSelected(photo)) {
      const newCurrentOrder = reject(currentOrder.photos, (el) => el.photo === photo);

      this.setState({
        ...this.state,
        currentOrder: {
          ...this.state.currentOrder,
          photos: newCurrentOrder
        }
      }, () => {
        typeof localStorage !== 'undefined' && localStorage.setItem('currentOrder', JSON.stringify(this.state.currentOrder));
      });
    } else {
      this.setState({
        ...this.state,
        currentOrder: {
          ...currentOrder,
          photos: [...currentOrder.photos, {
            photo,
            quantity: 1
          }]
        }
      }, () => {
        typeof localStorage !== 'undefined' && localStorage.setItem('currentOrder', JSON.stringify(this.state.currentOrder));
      });
    }
  }

  photoIsSelected = (photo) => {
    const {
      currentOrder: {
        photos
      }
    } = this.state;

    return photos.some((e) => e.photo === photo);
  }

  handleQuantityChange = (photo, newQuantity) => {
    if (newQuantity === 0) {
      this.addPhotoToState(photo);
    } else {
      this.setState({
        ...this.state,
        currentOrder: {
          ...this.state.currentOrder,
          photos: this.state.currentOrder.photos.map(el => (el.photo === photo ? { ...el, quantity: newQuantity } : el))
        }
      }, () => {
        localStorage && localStorage.setItem('currentOrder', JSON.stringify(this.state.currentOrder));
      });
    }
  }

  render() {
    const { albumPhotos } = this.props;
    const {
      currentOrder: {
        photos
      }
    } = this.state;

    return (
      <div>
        <Grid>
          <Row>
            <Col md={12}>
              <div style={{
                textAlign: 'center',
                marginBottom: 40
              }}>
                <Image size={30} color='#777' style={{ marginBottom: 15 }} />

                <div style={{
                  fontWeight: 500,
                  color: '#666',
                  textTransform: 'uppercase',
                  fontSize: 16
                }}>
                  {photos.length ? (
                    <span>
                      <strong>
                        <Pluralize singular={'foto'} count={photos.length} />
                      </strong>{" "}
                      <Pluralize singular={'selecionada'} count={photos.length} showCount={false} /> {" "}
                    </span>
                  ) : `Clique nas fotos que pretende encomendar`}
                </div>

                <div style={{
                  fontSize: 15,
                  color: '#b9b9b9',
                  marginTop: 10
                }}>
                  Para aumentar ou diminuir as quantidades de cada foto use os botões [ - ] e [ + ]
                </div>
              </div>
            </Col>
          </Row>

          <Container className="photos-order-grid">
            {albumPhotos.map(photo => {
              var photoName = photo.Key;
              var photoState = photos.find((e) => e.photo === photoName);

              return (
                <div key={photoName} style={{ position: 'relative' }}>
                  {this.photoIsSelected(photoName) &&
                    <PhotoQuantityHandler>
                      <QuantityHandler onClick={() => this.handleQuantityChange(photoName, photoState.quantity - 1)}>
                        -
                      </QuantityHandler>

                      <span>
                        {photoState.quantity}
                      </span>

                      <QuantityHandler onClick={() => this.handleQuantityChange(photoName, photoState.quantity + 1)}>
                        +
                      </QuantityHandler>
                    </PhotoQuantityHandler>
                  }

                  <Photo
                    onClick={() => this.addPhotoToState(photoName)}
                    className={this.photoIsSelected(photoName) && `selected`}
                    src={`${S3_ENDPOINT}${photoName}`}
                    alt={`Photo ${photoName}`}
                  />
                </div>
              );
            })}
          </Container>

          <br /><br /><br />

          <div style={{ clear: 'both' }} />

          {photos.length > 0 &&
            <div style={{
              textAlign: 'center'
            }}>
              <Anchor to={ORDER_CONFIRMATION}>
                Passo seguinte →
              </Anchor>
            </div>
          }
        </Grid>

        <BrowserView>
          {photos.length > 0 &&
            <div style={{
              position: 'fixed',
              zIndex: 9999,
              bottom: 25,
              right: 25
            }}>
              <span style={{
                background: 'rgba(49, 52, 63, .9)',
                color: '#fff',
                padding: '6px 18px',
                borderRadius: 3,
                fontSize: 12,
                textTransform: 'uppercase'
              }}>
                <span style={{ fontWeight: 600 }}>
                  {sumBy(photos, (el) => el.quantity)}
                </span> {" "}
                Fotos
              </span>
            </div>
          }
        </BrowserView>
      </div>
    );
  }
}

export default GalleryOrderMode;
