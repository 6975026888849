import React from 'react';

import { Body, Note } from './style';

export const Growth = () => (
  <Body>
    Olá, bem-vindos à galeria de provas.

    <br /><br />

    Apresentamos a vossa sessão, esperamos que gostem.

    <br /><br />

    Aqui deveram selecionar as 10 imagens digitais que estão incluídas no pack
    sessão de acompanhamento.

    <br /><br />

    Para seleccionar, basta clicar no botão “encomendar”, selecionando as imagens com um único
    click, no final preencha o formulário de contacto e confirme a encomenda.

    <br /><br />

    Se desejar receber mais que as 10 imagens digitais incluídas no pack.

    <br /><br />

    <ul>
      <li>
        Totalidade das imagens digitais disponíveis na galeria por apenas 50€
      </li>
    </ul>

    <Note>
      (No caso de optar pela galeria completa, basta nos informar que pretende a
      galeria na totalidade, não há necessidade de realizar encomenda.)
    </Note>

    <br /><br />

    <ul>
      <li>
        As imagens que seleccionar na encomenda serão editadas e finalizadas para entrega, este
        processo demorará entre 30 a 40 dias após a data da encomenda. Após edição as imagens seguem
        via email, através do site Smash. As restantes imagens não editadas, serão destruídas.
      </li>

      <li>
        Alertamos que as imagens apresentadas na presente galeria são imagens de prova,
        em baixa resolução, pelo que solicitamos, que não sejam utilizadas em
        partilhas, publicações ou utilização em redes sociais externas ao site hdphotographers.com
      </li>
    </ul>
  </Body>
);
