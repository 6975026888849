/* eslint-disable import/first */
typeof window !== 'undefined' && require('intersection-observer');

import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { graphql } from 'gatsby';
import { Lock, Unlock, Image } from 'react-feather';
import { LazyImage } from 'react-lazy-images';
import { BrowserView, MobileView } from 'react-device-detect';
import S3 from 'aws-sdk/clients/s3';

import Layout from '../../components/Layout';
import Breaker from '../../components/Breaker';
import GalleryOrderMode from '../../components/GalleryOrderMode';
import SEO from '../../components/seo';
import { Button, Title } from '../../styles/base';
import Loader from '../../images/Loader.svg';
import { S3_ENDPOINT } from '../../constants/External';
import { S3_CONFIG } from '../../constants/S3';
import Templates from '../../components/Templates';

import { CustomForm } from './style';

var s3 = new S3(S3_CONFIG);

class PrivateGallery extends Component {
  constructor(props) {
    super(props);

    const { location: { hash } } = this.props;

    this.state = {
      hasAccess: false,
      accessCode: '',
      hasError: false,
      orderMode: hash && hash === '#order-mode' ? true : false,
      photos: [],
      makingS3Request: true
    }
  }

  handleChange = (e) => {
    const { value } = e.target;

    this.setState({
      ...this.state,
      hasError: false,
      accessCode: value
    })
  }

  componentDidMount() {
    const {
      data: {
        markdownRemark: {
          id,
          frontmatter: {
            code
          }
        }
      }
    } = this.props;

    const accessToken = typeof localStorage !== 'undefined' && localStorage.getItem(id);

    if (accessToken && code === accessToken) {
      this.setState({
        ...this.state,
        hasAccess: true
      }, () => {
        const {
          data: {
            markdownRemark: {
              frontmatter: {
                folder
              }
            }
          }
        } = this.props;

        s3.listObjects({ Delimiter: '/', Prefix: `galerias-privadas/${folder}/` }, (err, data) => {
          data.Contents.shift();

          this.setState({
            ...this.state,
            makingS3Request: false,
            photos: data.Contents
          })
        });
      });
    }
  }

  toggleOrderMode = () => {
    this.setState({
      ...this.state,
      orderMode: !this.state.orderMode
    }, () => {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      data: {
        markdownRemark: {
          id,
          frontmatter: {
            code
          }
        }
      }
    } = this.props;
    const { accessCode } = this.state;

    if (accessCode === code) {
      this.setState({
        ...this.state,
        hasAccess: true
      });

      typeof localStorage !== 'undefined' && localStorage.setItem(id, code);

      const {
        data: {
          markdownRemark: {
            frontmatter: {
              folder
            }
          }
        }
      } = this.props;

      s3.listObjects({ Delimiter: '/', Prefix: `galerias-privadas/${folder}/` }, (err, data) => {
        data.Contents.shift();

        this.setState({
          ...this.state,
          makingS3Request: false,
          photos: data.Contents
        })
      });
    } else {
      this.setState({
        ...this.state,
        hasError: true
      })
    }
  }

  renderTemplate(param, couple = null) {
    switch (param) {
      case 'FATHERS_DAY':
        return <Templates.FathersDay />;
      case 'NEWBORN':
        return <Templates.Newborn />;
      case 'GROWTH':
        return <Templates.Growth />;
      case 'MINI_THEMED':
        return <Templates.MiniThemed />;
      case 'BAPTISM':
        return <Templates.Baptism />;
      case 'MATERNITY_STUDIO':
        return <Templates.MaternityStudio />;
      case 'SMASH':
        return <Templates.SmashTheCake />;
      case 'WEDDING':
        return <Templates.Wedding couple={couple} />;
      case 'CHRISTMAS':
        return <Templates.Christmas />;
      default:
        return;
    }
  }

  render() {
    const {
      data: {
        markdownRemark: {
          id,
          frontmatter: {
            title,
            folder,
            template,
            couple
          },
          fields: {
            slug
          }
        }
      }
    } = this.props;
    const { hasAccess, accessCode, hasError, orderMode, makingS3Request, photos } = this.state;

    return (
      <Layout>
        <SEO title={title} keywords={[`gatsby`, `application`, `react`]} />
        <BrowserView>
          {!orderMode && hasAccess &&
            <Button
              onClick={this.toggleOrderMode}
              style={{
                position: 'fixed',
                bottom: 25,
                right: 25
              }}
            >
              Encomendar
              <Image
                size={20}
                style={{
                  verticalAlign: 'middle',
                  margin: '-3px 0 0 10px'
                }}
              />
            </Button>
          }
        </BrowserView>

        {hasAccess ? (
          <div>
            <Title>{title}</Title>
            <Breaker />

            {makingS3Request &&
              <div style={{
                textAlign: 'center',
                padding: '100px 0'
              }}>
                <Loader />

                <h3 style={{
                  fontSize: 19,
                  color: '#777',
                  fontWeight: 500,
                  marginTop: 25
                }}>
                  A carregar fotos ...
                </h3>
              </div>
            }

            {orderMode ?
              <div>
                <GalleryOrderMode
                  albumPhotos={photos}
                  albumName={folder}
                  albumId={id}
                  albumSlug={slug}
                />

                <div style={{ textAlign: 'center' }}>
                  <Button onClick={this.toggleOrderMode} isRaw>
                    Voltar ao álbum
                  </Button>
                </div>
              </div>
            :
            <Grid>
              <Row center="md">
                <Col md={8}>

                  {this.renderTemplate(template, couple)}

                  {template &&
                    <div style={{ textAlign: 'left', marginBottom: 35, marginTop: 50, fontSize: 16 }}>
                      Obrigado pela vossa preferência
                      <div style={{
                        fontFamily: 'Covered By Your Grace',
                        fontSize: 28,
                        color: '#222',
                        marginTop: 10
                      }}>
                        H&D
                      </div>
                    </div>
                  }

                  {photos.length > 0 && photos.map(photo => (
                    <Row center='xs' key={photo.Key}>
                      <Col md={12}>
                        <LazyImage
                          src={`${S3_ENDPOINT}${photo.Key}`}
                          alt='Pic'
                          style={{ borderRadius: 6 }}
                          placeholder={({ imageProps, ref }) => (
                            <div style={{ padding: '80px 0' }} ref={ref}>
                              <Loader />
                            </div>
                          )}
                          actual={({ imageProps }) => <img {...imageProps} alt={imageProps.alt} />}
                        />
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>

              {!makingS3Request &&
                <div>
                  <Breaker />

                  <Row>
                    <Col md={12}>
                      <div style={{ textAlign: 'center' }}>
                        <h3>Deseja realizar uma encomenda?</h3>
                        <BrowserView>
                          <div
                            style={{
                              fontSize: 15,
                              color: '#999',
                              marginBottom: 50
                            }}
                          >
                            Clique no botão abaixo para selecionar as imagens que quer na sua encomenda.
                          </div>

                          <Button onClick={this.toggleOrderMode}>
                            Escolher fotos para encomenda
                          </Button>
                        </BrowserView>

                        <MobileView>
                          <div style={{
                            color: '#f9a11b',
                            fontSize: 15,
                            background: 'rgba(249, 161, 26, .2)',
                            padding: '8px 15px',
                            borderRadius: 6,
                            textAlign: 'left'
                          }}>
                            Para uma melhor experiência a opção de encomendas está
                            disponível apenas na versão em computador, se deseja efectuar
                            uma encomenda aceda ao álbum através do seu computador ou tablet.
                          </div>
                        </MobileView>
                      </div>
                    </Col>
                  </Row>
                </div>
              }
            </Grid>
            }
          </div>
        ) : (
          <Grid>
            <Row>
              <Col md={12}>
                <CustomForm onSubmit={this.handleSubmit}>
                  <Lock size={56} color='#bbb' />

                  <label>Acesso ao álbum <span style={{ fontWeight: 600, background: '#f1f1f1', padding: '3px 8px', borderRadius: 4 }}>{title}</span> está bloqueado:</label>

                  {hasError &&
                    <div style={{ marginTop: 30 }}>
                      <span style={{
                        color: '#f9a11b',
                        fontSize: 15,
                        background: 'rgba(249, 161, 26, .2)',
                        padding: '8px 15px',
                        borderRadius: 6
                      }}>
                        Esse código não está correcto, tente novamente ou entre em contacto connosco.
                      </span>
                    </div>
                  }

                  <input
                    type="text"
                    placeholder="Ex.: WMJULEBFOFIV"
                    onChange={this.handleChange}
                    value={accessCode}
                  />

                  <br />

                  <Button>
                    <Unlock size={18} style={{ verticalAlign: 'middle', margin: '-7px 8px 0 0' }} />
                    Validar código de acesso
                  </Button>
                </CustomForm>
              </Col>
            </Row>
          </Grid>
        )}
      </Layout>
    );
  }
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      frontmatter {
        code
        title
        folder
        template
        couple
      }
      fields {
        slug
      }
    }
  }
`;

export default PrivateGallery;
