import { FathersDay } from './FathersDay';
import { Newborn } from './Newborn';
import { Growth } from './Growth';
import { MiniThemed } from './MiniThemed';
import { Baptism } from './Baptism';
import { MaternityStudio } from './MaternityStudio';
import { SmashTheCake } from './SmashTheCake';
import { Wedding } from './Wedding';
import { Christmas } from './Christmas';

export default {
  FathersDay,
  Newborn,
  Growth,
  MiniThemed,
  Baptism,
  MaternityStudio,
  SmashTheCake,
  Wedding,
  Christmas
};
