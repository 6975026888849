import React from 'react';

import { Body, Note } from './style';

export const FathersDay = () => (
  <Body>
    Olá, bem-vindos à galeria de provas.

    <br />

    Apresentamos a vossa sessão, esperamos que gostem.

    <br /><br />

    Aqui devem seleccionar as 20 imagens digitais que estão incluídas no pack mini sessão, para edição.
    Para seleccionar, basta clicar no botão “encomendar”, selecionando as imagens com um único click, no final preencha o formulário de contacto e confirme a encomenda.

    <br /><br />

    Se desejar receber mais que as 20 imagens digitais incluídas no pack.

    <br /><br />

    <ul>
      <li>
        Totalidade das imagens digitais disponíveis na galeria - 30€
      </li>
    </ul>

    <Note>
      (No caso de optar pela galeria completa, basta nos informar que pretende a galeria na totalidade, não há necessidade de realizar encomenda.)
    </Note>

    <br /><br />

    As imagens que seleccionar na encomenda serão editadas e finalizadas para entrega, este processo demorará entre 15 a 20 dias após a data da encomenda.

    <br /><br />

    Após edição as imagens seguem via email, através do site Smash. As restantes imagens não editadas, serão destruídas.
    Alertamos que as imagens apresentadas na presente galeria são imagens de prova, em baixa resolução, pelo que solicitamos,
    que não sejam utilizadas em partilhas, publicações ou utilização em redes sociais externas ao site hdphotographers.com

  </Body>
);
