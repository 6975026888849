import React from 'react';

import { Body, Note, Table } from './style';

export const MiniThemed = () => (
  <Body>
    Olá, bem-vindos à galeria de provas.

    <br /><br />

    Apresentamos a vossa sessão, esperamos que gostem.

    <br /><br />

    Aqui deve encomendar as suas imagens favoritas, correspondendo ao número de
    imagens que estão incluídas no pack de sessão que adquiriu.

    <br /><br />

    Para seleccionar, basta clicar no botão “encomendar”, selecionando as imagens com um único
    click, no final preencha o formulário de contacto e confirme a encomenda.

    <br /><br />

    Se desejar receber mais que as 10 imagens digitais incluídas no pack.

    <br /><br />

    <ul>
      <li>
        Totalidade das imagens digitais disponíveis na galeria
      </li>
    </ul>

    <Note>
      (No caso de optar pela galeria completa, basta nos informar que pretende a
      galeria na totalidade, não há necessidade de realizar encomenda.)
    </Note>

    <Table>
      <thead>
        <tr>
          <th />
          <th>Número de imagens digitais incluídas</th>
          <th>Galeria Completa</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <th>Um cenário</th>
          <td>20</td>
          <td>30€</td>
        </tr>

        <tr>
          <th>Dois cenários</th>
          <td>35</td>
          <td>30€</td>
        </tr>
      </tbody>
    </Table>

    <br /><br />

    <ul>
      <li>
        As imagens que seleccionar na encomenda serão editadas e finalizadas para entrega, este
        processo demorará entre 20 a 30 dias após a data da encomenda. Após edição as imagens seguem
        via email, através do site Smash. As restantes imagens não editadas, serão destruídas.
      </li>

      <li>
        Alertamos que as imagens apresentadas na presente galeria são imagens de prova,
        em baixa resolução, pelo que solicitamos, que não sejam utilizadas em
        partilhas, publicações ou utilização em redes sociais externas ao site hdphotographers.com
      </li>
    </ul>
  </Body>
);
