import React from 'react';

import { Body, Note, Table } from './style';

export const SmashTheCake = () => (
  <Body>
    Olá, bem-vindos à galeria de provas.

    <br /><br />

    Apresentamos a vossa sessão, esperamos que gostem.

    <br /><br />

    Aqui deve encomendar as suas imagens favoritas, correspondendo ao número de
    imagens que estão incluídas no pack de sessão que adquiriu.

    <br /><br />

    Para seleccionar, basta clicar no botão “encomendar”, selecionando as imagens
    com um único click, e no final preencha o formulário de contacto e confirme a encomenda.

    <br /><br />

    Se desejar receber mais que as imagens digitais incluídas no pack, temos as seguintes
    opções de compra:

    <br /><br />

    <ul>
      <li>Totalidade das imagens digitais disponíveis na galeria</li>
    </ul>

    <Note>
      (No caso de optar pela galeria completa, basta nos informar que pretende a galeria
      na totalidade, não há necessidade de realizar encomenda.)
    </Note>

    <Table>
      <thead>
        <tr>
          <th />
          <th>Número de imagens digitais incluídas</th>
          <th>Galeria Completa</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <th>Sessão Silver</th>
          <td>15</td>
          <td>50€</td>
        </tr>

        <tr>
          <th>Sessão Gold</th>
          <td>40</td>
          <td>50€</td>
        </tr>
      </tbody>
    </Table>

    <br /><br />

    <ul>
      <li>
        Na aquisição de um álbum ou book, todas as imagens incluídas no álbum/book
        são entregues em formato digital, sem pagamento extra
      </li>

      <li>
        As imagens que selecionar na encomenda serão editadas e finalizadas para entrega,
        este processo demorará entre 30 a 40 dias após a data da encomenda. Após edição as
        imagens seguem via email, através do site Smash. As restantes imagens não editadas, serão destruídas.
      </li>

      <li>
        Alertamos que as imagens apresentadas na presente galeria são imagens de prova,
        em baixa resolução, pelo que solicitamos, que não sejam utilizadas em partilhas,
        publicações ou utilização em redes sociais externas ao site hdphotographers.com.
      </li>
    </ul>
  </Body>
);
