import styled from 'styled-components';

export const Container = styled.div`
  /* Prevent vertical gaps */
  line-height: 0;

  -webkit-column-count: 3;
  -webkit-column-gap:   0px;
  -moz-column-count:    3;
  -moz-column-gap:      0px;
  column-count:         3;
  column-gap:           0px;

  div {
    margin: 5px;
    display: inline-block;
  }

  img[src*="static"] {
  }
`;

export const Photo = styled.img`
  transition: all .3s linear;
  /* Just in case there are inline attributes */
  width: 100% !important;
  height: auto !important;
  margin: 0;
  border-radius: 6px;
  cursor: pointer;
  cursor: hand;
  image-rendering: crisp-edges;

  &:hover {
    opacity: .7;
  }

  &.selected {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: .3;
  }
`;

export const PhotoQuantityHandler = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 9999;

  span {
    background: #9b87dd;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    border-radius: 3px;
    padding: 4px 10px;
    margin-right: 3px;
    cursor: pointer;
    cursor: hand;

    &:hover {
      background: #8169d4;
    }
  }
`;

export const QuantityHandler = styled.button`
  background: #9b87dd;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  border-radius: 3px;
  padding: 12px 10px;
  margin-right: 3px;
  cursor: pointer;
  cursor: hand;
  display: inline-block;
  outline: 0;
  -webkit-appearance: none;
  border: none;

  &:hover {
    background: #8169d4;
  }
`;
