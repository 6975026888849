import styled from 'styled-components';

export const CustomForm = styled.form`
  text-align: center;

  label {
    display: block;
    text-transform: uppercase;
    font-size: 17px;
    font-weight: 500;
    margin-top: 30px;
  }

  input {
    border: 1px #ddd solid;
    font-size: 22px;
    padding: 30px 50px;
    margin-top: 30px;

    text-align: center;
  }

  button {
    margin-top: 20px;
  }
`;
