import React from 'react';

import { Body, Note, Table } from './style';

export const Wedding = ({ couple }) => (
  <Body>
    Olá bem vindo à galeria de provas do casamento de {couple}.

    <br /><br />

    Aqui pode encomendar as fotografias que deseja imprimir em tamanho 15x20cm,
    o custo de cada impressão é de 2,5€.

    <br /><br />

    As fotografia impressa podem ser levantadas no nosso estúdio, no Cartaxo. Se
    desejar receber as fotografias em sua casa, acresce o valor dos portes à
    encomenda.

    <br /><br />
      
    Qualquer dúvida estamos ao vosso dispor: <strong>916 793 411</strong>
  </Body>
);
