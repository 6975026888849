import React from 'react';

import { Body, Note, Table } from './style';

export const Christmas = () => (
  <Body>
    Olá, bem-vindos à galeria de provas.

    <br />

    Apresentamos a vossa sessão, esperamos que gostem.

    <br /><br />

    Aqui deve encomendar as suas imagens favoritas, correspondendo ao número de imagens que estão incluídas no pack da sessão que adquiriu.
    Para seleccionar, basta clicar no botão “encomendar”, selecionando as imagens com um único click, no final preencha o formulário de contacto e confirme a encomenda.

    <br /><br />

    Se desejar receber mais que as imagens digitais incluídas no pack, temos as seguintes opções de compra:

    <br /><br />

    <ul>
      <li>
        Totalidade das imagens digitais disponíveis na galeria
      </li>
    </ul>

    <Note>
      (No caso de optar pela galeria completa, basta nos informar que pretende a galeria na totalidade, não há necessidade de realizar encomenda.)
    </Note>

    <br />

    <ul>
      <li>
        10 fotos impressas, tamanho 10x15 8€
      </li>
      <li>
        10 fotos impressas, tamanho 15x20 20€
      </li>
    </ul>

    <br />

    <Note>
      Informação, os packs que incluem fotos impressas, as mesmas serão escolhidas por nós.A galeria estará disponível para visualização e escolha, durante 5 dias.
    </Note>

    <Table>
      <thead>
        <tr>
          <th />
          <th>Número de imagens digitais incluídas</th>
          <th>Valor de galeria completa</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <th>Sessão de exterior</th>
          <td>20</td>
          <td>30€</td>
        </tr>

        <tr>
          <th>Estúdio mini infantil</th>
          <td>10</td>
          <td>25€</td>
        </tr>

        <tr>
          <th>Estúdio Pack 1</th>
          <td>15</td>
          <td>30€</td>
        </tr>

        <tr>
          <th>Estúdio Pack 2</th>
          <td>30</td>
          <td>30€</td>
        </tr>

        <tr>
          <th>Exterior + Pack 2</th>
          <td>60</td>
          <td>40€</td>
        </tr>
      </tbody>
    </Table>

    <br /><br />

    <ul>
      <li>
        Na aquisição de um álbum ou book, todas as imagens incluídas no álbum/book são entregues em formato digital, sem pagamento extra.
      </li>
    </ul>

    <br /><br />

    As imagens que selecionar na encomenda serão editadas e finalizadas para entrega, este processo demorará entre 30 a 40 dias após a data da encomenda.

    <br /><br />

    Após edição as imagens seguem via email, através do site Smash. As restantes imagens não editadas, serão destruídas.
    Alertamos que as imagens apresentadas na presente galeria são imagens de prova, em baixa resolução, pelo que solicitamos,
    que não sejam utilizadas em partilhas, publicações ou utilização em redes sociais externas ao site hdphotographers.com

  </Body>
);
